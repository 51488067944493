<template>
  <!-- 仮申請時発行のTokenを検証して完了メッセージを出す -->
  <div v-if="!isProcessing">
    <b-card v-if="isActivated" no-body class="py-4">
      <b-card-body>
        <div class="text-center">
          <b-icon
            icon="check-circle"
            scale="3"
            variant="cmain"
            class="mb-5"
          ></b-icon>
        </div>
        <b-card-text class="lead text-center text-cmain">
          MYPLR口座開設申請をいただき<br />ありがとうございます。
        </b-card-text>
        <b-card-text>
          1週間以内に、登録された連絡先に、SMSで口座情報をお知らせしますので、しばらくお待ちください。
        </b-card-text>
      </b-card-body>
    </b-card>
    <b-card v-else no-body class="py-4">
      <b-card-body>
        <div class="text-center">
          <b-icon
            icon="exclamation-triangle"
            scale="3"
            variant="cmain"
            class="mb-5"
          ></b-icon>
        </div>
        <b-card-text class="lead text-center text-cmain">
          {{ errorMessage }}
        </b-card-text>
        <b-card-text>
          口座開設申請の完了ができませんでした。お手数ですが管理者へ連絡または再申請をおこなってください。MYPLR口座開設トップページに移動します。
        </b-card-text>
        <b-form-row>
          <b-col offset-sm="3" sm="6" offset-md="4" md="4">
            <router-link to="/account-opening">
              <b-button variant="csub" class="w-100"> OK </b-button>
            </router-link>
          </b-col>
        </b-form-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isProcessing: true, //処理中
      isActivated: false, //有効化済
      errorMessage: null,
    }
  },
  created() {
    this.validateToken()
  },
  methods: {
    validateToken() {
      // 仮申請時発行のTokenを検証
      const applicationId = this.$route.params.applicationId
      const token = this.$route.query.token
      console.log('applicationId', applicationId)
      console.log('token', token)
      this.$axiosToken
        .post('/account_opening/accept_application/' + applicationId, {
          token: token,
        })
        .then((response) => {
          console.log(response)
          this.isActivated = true
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            this.errorMessage = error.response.data.detail
          } else {
            this.errorMessage = '口座開設申請の完了に失敗しました。'
          }
        })
        .finally(() => (this.isProcessing = false))
    },
  },
}
</script>
